@import "ng.theme";
@import "core";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
/* You can add global styles to this file, and also import other style files */

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: rgb(10, 198, 10) !important; /* Pour l'état activé */
}

.mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: rgb(255, 0, 0) !important; /* Pour l'état désactivé */
}

html,
body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow: hidden;
}

.txt-info {
  color: $info-color;
}

.txt-danger {
  color: $danger-color;
}

.txt-success {
  color: $success-color;
}

.txt-white {
  color: $white-color;
}

.alert {
  color: $alert-txt;
}

.alert-success {
  background-color: $success-color;
}

.alert-danger {
  background-color: $danger-color;
}

.alert-info {
  background-color: $info-color;
}


.bg-success {
  background-color: $success-color;
}

.confirm-dialog mat-dialog-container {
  border-radius: 1rem !important;
  padding: 2rem !important;
}

.table-responsive {
  width: 100%;
  overflow-x: scroll;
  position: relative;
  flex-grow: 1;

  .loader {
    width: 100%;
    padding: 5rem;
    box-sizing: border-box;
    display: grid;
    box-sizing: border-box;
    place-items: center;
  }

  .no-data {
    width: 100%;
    height: 60px;
    display: grid;
    place-items: center;

    span {
      font-size: 2rem;
    }
  }

  @include desktop-device {
    &::-webkit-scrollbar {
      width: 0 !important;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    box-sizing: border-box;
  }

  table {
    width: 100%;
    min-width: 65rem;

    .data-row:hover {
      background-color: $table-row-hover;
    }
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  word-break: break-all;
}

ul li {
  list-style-type: none;
}


img{
  max-width: 100%;
}

// product-details-page //
.enable-disable-item .mat-slide-toggle .mat-slide-toggle-label {
  margin-left: 1rem;
}

.spaces-boxes h3 {
  font-weight: 500;
}

//=====user-details-page===========//
.prodtuct-selling .mat-tab-label-active {
  padding: 0 0;
  background: #757575 !important;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
}

.prodtuct-selling .mat-tab-label {
  width: 50%;
  background-color: #acacac;
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  padding: 0 0;
}

.prodtuct-selling .mat-tab-label:nth-child(1) {
  margin: 0 -25px 0 0;
}

.prodtuct-selling .mat-tab-label:nth-child(2) {
  margin: 0 -25px 0 0;
}

.prodtuct-selling .mat-tab-labels {
  justify-content: center;
}

.table-height-fixd {
  height: 680px;
  overflow-y: auto !important;
}

// ============modal-input=========//@at-root
.sub-category-modal .form-inputs .form-inputs-item {
  width: 70% !important;
}

.review-section {
  margin-top: 1rem;
}

.category-listmodal {
  width: 50%;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 45px;
}

.sub-category-modal .grid .img {
  height: 150px !important;
  width: 233px !important;
}

.sub-category-modal img {
  height: 150px !important;
  width: 233px !important;
}

.review-section h3 {
  font-weight: 500;
}

.content1-color {
  display: flex;
}

.content1-color .detail-item {
  padding-right: 5rem;
}

// =========product-details-page==========//


.mat-select-value{text-align: center !important;}


// .slide img {
//   height: 180px !important;
//   width: 300px !important;
//   margin: 0 auto;
// }

.upload-graph #canvas{
  max-width: 100% !important;
}

.bar-graph {
  text-align: right;
}
.line-graph{
  text-align: right;
}

.revenue-graph #lineChart{
  max-width: 100% !important;
}


// ===============custom scroll starts=============

/* width */
  ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
 ::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
//  border-radius: 10px;
//  color: rgba(0, 0, 0, 0.2);

}

/* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
  color: rgba(0, 0, 0, 0.4);
}

// ===============custom scroll ends=============
.clickable {
  cursor: pointer;
}