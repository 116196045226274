// @import "~@angular/material/theming";
@import "~@angular/material/theming";

@mixin core-theming($theme) {
  $foreground: map-get($theme, "foreground");
  $background: map-get($theme, "background");
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  $primary-color: mat-color($primary);
  $accent-color: mat-color($accent);
  $warn-color: mat-color($warn);
  $text-color: map-get($foreground, "text");
  $secondary-color: map-get($foreground, secondary-text);
  $disabled-color: map-get($foreground, disabled-text);

  .txt-primary {
    color: $primary-color !important;
  }

  .txt-secondary {
    color: $secondary-color !important;
  }

  .txt-warn {
    color: $warn-color !important;
  }

  .txt-accent {
    color: $accent-color;
  }

  .border-accent {
    border: 0.1rem solid $accent-color;
  }

  .bg-primary {
    background-color: $primary-color !important;
  }

  .bg-accent {
    background-color: $accent-color !important;
  }

  .bg-warn {
    background-color: $warn-color !important;
  }

  .border-primary {
    border-color: $primary-color !important;
  }

  .mat-header-row{
    background-color: $primary-color;
    .mat-header-cell{
      color: #fff;
      font-weight: bold;
    }
    .mat-sort-header-arrow{
      color: #fff;
    }
  }

  .bg-gradient {
    background: linear-gradient(
      to right,
      $primary-color 0%,
      $primary-color 50%,
      $primary-color,
      $accent-color 50%,
      $accent-color
    ) !important;
  }

  .content {
    height: auto;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
    margin-bottom: 10px;
    h1 {
      width: 100%;
      margin-left: 2rem;
      text-decoration: underline;
      color: $primary-color;
      font-weight: bold;
      text-align: center;
    }

    .mid-bar {
      display: flex;
      align-items: center;
      .mid-bar-item{
        margin: 0% 1rem;
      }
    }
  }

  .total-box {
    color: $primary-color;
    border: 0.1rem solid $primary-color;
    border-radius: 0.6rem;
    background-color: #fff;
    padding: 0.4rem;
  }

  .search-bar-box {
    margin: 0% 5px;
    width: 30%;
    height: 36px;
    box-sizing: border-box;
    border: 0.1rem solid $primary-color;
    padding: 0.5rem;
    background-color: #fff;
    border-radius: 0.5rem;
    display: flex;
    margin-right: 0.5rem;
    overflow: hidden;
    max-width: 22rem;

    .search-bar {
      height: 100%;
      width: 100%;
      display: flex;

      input {
        border: none;
        outline: none;
        flex: 1;
        font-size: 1.6rem;

        &::placeholder {
          color: $primary-color;
          height: 3rem;
        }
      }
    }
  }
}
